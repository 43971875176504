@import 'variables';

@font-face {
  font-family: 'Circe-Regular';
  src: url('./assets/fonts/Circe-Regular.ttf') format('truetype');
}

* {
  margin: 0;
  font-family: 'Circe-Regular', sans-serif !important;
}

#root {
  display: flex;
  min-height: 100vh;
}

//sm
@media (min-width: 600px) {
  html {
    font-size: 12px !important;
  }
}

//md
@media (min-width: 960px) {
  html {
    font-size: 12px !important;
  }
}

//lg
@media (min-width: 1280px) {
  html {
    font-size: 14px !important;
  }
}

//xl
@media (min-width: 1920px) {
  html {
    font-size: 16px !important;
  }
}
